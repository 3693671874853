
import { useEffect } from 'react';

import jax from '../helper/jax';
import { useParams } from 'react-router-dom';
import { Box, Card, Dialog, Divider, Link, Stack, TextField } from '@mui/material';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {can, resources, actions} from '../features/access';

import { useState } from 'react';
import { CheckBox, CheckBoxOutlineBlank, RadioButtonChecked, RadioButtonUnchecked, RecordVoiceOverTwoTone } from '@mui/icons-material';
import { asDate } from '../helper/util';
import { useTheme } from '@emotion/react';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button } from '@mui/material';
import CourseList from '../components/CourseList';
import algoliasearch from 'algoliasearch/lite';
import { getSearchClient } from '../helper/search';
import {roles, roleNames} from '../features/access';

function CourseSearch(props) {
	const {open, onSelect, onClose} = props;
	const [courses, setCourses] = useState([]);
	const [query, setQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [selected, setSelected] = useState(null);
	const {config} = useSelector((state) => state.data);
	const {user} = useSelector((state) => state.data);
	
	const tenantInfo = useSelector(state=>state.app.tenantInfo);
	
	const search_client = getSearchClient(tenantInfo?.sk);
	const index = search_client.initIndex(config.course_index);

	useEffect(function() {
		if (open){
			setLoading(true);
			
			index.search(query.length > 2 ? query : '*', {
				
				hitsPerPage: 25
			  }).then(( resp ) => {
				var c = resp.hits.map(h=>{
					h.start_date = asDate(h.start_date, 'YYYY-MM-DD');
					h.expected_end_date = asDate(h.expected_end_date, 'YYYY-MM-DD');
					h.end_date = asDate(h.end_date, 'YYYY-MM-DD');
					h.enrollment_end_date = asDate(h.enrollment_end_date, 'YYYY-MM-DD');
					h.canceled_on = asDate(h.canceled_on, 'YYYY-MM-DD');
					h.closed_on = asDate(h.closed_on, 'YYYY-MM-DD');
					return h;
				})
				setCourses(c);
			  }).catch(err=>{
				console.error(err);
			  }).finally(()=>{
				setLoading(false)
			  });
			}
		
	}, [query, open]);

	return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth >
		<Box class="card-header" sx={{p:2, borderBottom: 'solid 1px #ddd'}}>Find a course</Box>
		<Box p={2}>
			<TextField size="small" label="Search" variant="outlined" fullWidth value={query} onChange={(e)=>setQuery(e.target.value)}/>
		</Box>
		<Divider />
		<DialogContent sx={{p:0, minHeight: 400, maxHeight: 400}}>
			<Box >
				{!!courses?.length && <CourseList courses={courses} enableSelection onSelect={(id, code)=>setSelected(id)}/>}
				{!courses?.length && <Box p={4} sx={{color: '#999', fontStyle:'italic'}} textAlign="center">
					No courses found.
				</Box>}
			</Box>
		</DialogContent>
		<Divider />
		<DialogActions>
			<Button onClick={onClose}>Cancel</Button>
			<Button variant="contained" onClick={()=>onSelect(selected)} disabled={!selected}>Assign Course</Button>
		</DialogActions>
	</Dialog>;
}

function Answer(props) {
	const {question, all_answers} = props;
	var els = <></>;
	var choices = question.choices || [];
	if (question.allow_other && !choices.find(x=>x.code == 'OTHER')) {
		choices.push({code: 'OTHER', label: 'Other'})
	}
	var className = "";
	if (question.question_type == "multi-select") {
		var sels = question.answer.split('|').filter(x=>!!x);
		className = "multi-choice";
		els = choices.map((choice, i)=>{
			var checked = sels.includes(choice.code);
		
			return <Box key={i}>
				<Stack className={checked ? 'item-checked' : 'item-unchecked'} direction="row" alignItems="center" key={i} spacing={0.5}>
					{checked ? <CheckBox/> : <CheckBoxOutlineBlank/>}
					<Box className="item-label">{choice.label}</Box>
				</Stack>
				{question.other && choice.code =="OTHER" && checked && <Box className="answer-indent" mt={0.5} sx={{whiteSpace: "pre-wrap"}}>{question.other}</Box>}
			</Box>;
		});
	} else if (question.question_type == "radio") {
		className = "multi-choice";
		els = choices.map((choice, i)=>{
			var checked = question.answer == choice.code;
			return <Box key={i}>
				<Stack className={checked ? 'item-checked' : 'item-unchecked'} direction="row" alignItems="center" key={i} spacing={0.5}>
					{checked ? <RadioButtonChecked/> : <RadioButtonUnchecked/>}
					<Box className="item-label">{choice.label}</Box>
				</Stack>
				{question.other && choice.code =="OTHER" && checked && <Box className="answer-indent" mt={0.5}  sx={{whiteSpace: "pre-wrap"}}>{question.other}</Box>}
			</Box>
			
		});
	} else {
		els = <Box className="answer-indent" sx={{whiteSpace: "pre-wrap"}}>{question.answer}</Box>
	}
	return <Stack pt={0.5} className={className} spacing={0.5}>{els}</Stack>;
}

function ReportHeader(props) {
	const {report, role} = props;	

	return <Stack direction={{xs:'column', lg: 'row'}} px={{xs: 3, lg:4}} pt={2} pb={4} spacing={{xs:2, lg:14}} >
		<Box>
			<Box className="primary" sx={{fontWeight: 500,  mb:0.5}}>Submitted On</Box>
			<Box className="answer-indent">{asDate(report?.submitted)}</Box>
		</Box>
		<Box>
			<Box className="primary" sx={{fontWeight: 500,  mb:0.5}}>Course</Box>
			{report?.course_code && <Box className="answer-indent">{report?.course_name}</Box>}
			{!report?.course_code && <Box className="answer-indent" sx={{color: '#999 !important', fontStyle:'italic'}}>
				Unknown	
			</Box>}
		</Box>
		<Box>
			<Box className="primary" sx={{fontWeight: 500,  mb:0.5}}>Program</Box>
			{report?.program_id && <Box className="answer-indent">{report?.program_name}</Box>}
			{!report?.program_id && <Box className="answer-indent" sx={{color: '#999 !important', fontStyle:'italic'}}>
				Unknown	
			</Box>}
		</Box>
		<Box>
			<Box className="primary" sx={{fontWeight: 500,  mb:0.5}}>Reporting Role</Box>
			{role && <Box className="answer-indent">{role}</Box>}
			{!role && <Box  className="answer-indent" sx={{color: '#999 !important', fontStyle:'italic'}}>
				Unknown
			</Box>}
		</Box>
		
		
	</Stack>
}

export default function SafetyReport(props) {
	const {object_id} = props;
	const params = useParams();
	const [report, setReport] = useState();
	const [answers, setAnswers] = useState();
	const {user} = useSelector((state) => state.data);
	const [courseSearchOpen, setCourseSearchOpen] = useState(false);
	const [role, setRole] = useState();
	const theme = useTheme();
	
	var uuid = params.uuid || params.object_id;

	useEffect(function() {
		jax.get(`/app/safety_reports/${uuid || object_id}?unit=${params.unit_id}`).then(eval_data => {
			setReport(eval_data);

			var all_answers = {};
			eval_data.sections.map((section)=>{
				section.blocks.map((block)=>{
					all_answers[block.block_id] = {};
					block.questions.map((question)=>{
						all_answers[block.block_id][question.question] = question.answer;
						question.other && (all_answers[block.block_id][question.question + '_OTHER'] = question.other);
					});
				});
			});

			setAnswers(all_answers);

			if (eval_data.role_id) {
				roleNames[eval_data.role_id] && setRole(roleNames[eval_data.role_id]);
			} else {
				var roleEnum = all_answers['COURSE_CONTEXT']['COURSE_ROLE'];
				if (roleEnum == 'OTHER') {
					setRole(all_answers['COURSE_CONTEXT']['COURSE_ROLE_OTHER']);
				} else {
					var role_id = roles[roleEnum];
					setRole(roleNames[role_id]);
				}
			}

		}). catch(err => {
			debugger;
		});
	}, [object_id, uuid]);

	const assignCourse = (id, code)=>{
		setCourseSearchOpen(false);
		jax.post('/app/evals/assign', {uuid: uuid || object_id, course_id: id}).then((res)=>{
			setReport({...report, course_id: res.course_id, course_code: res.course_code, program_id: res.program_id, program_name: res.program_name, course_name: res.course_name});
		}).catch(err=>{
			debugger;
		});
	}

	return report ? <Stack spacing={1} mb={8} className="eval-report">
		<CourseSearch open={courseSearchOpen} onClose={()=>setCourseSearchOpen(false)} onSelect={assignCourse}></CourseSearch>
		<Box p={2}>
			<Breadcrumbs separator="›">
				{params.uuid ? <RouterLink to="/safety_reports">Safety Reports</RouterLink> : <RouterLink to={`/commands/${params.unit_id}/safety`}>Safety Reports</RouterLink>}
				{report && <Typography>Safety Report</Typography>}
			</Breadcrumbs>
		</Box>
		<Divider sx={{margin:0}}></Divider>
		<Stack spacing={1} sx={{mt:'0 !important'}} className="printable">
			
				{report && !report.course_code && <Box p={2} sx={{marginTop: '0 !important', color: theme.palette.warning.dark, borderBottom: `solid 1px ${theme.palette.warning.dark}`}} className="warning-stripes" >
					Course information was not supplied with this report.  To ensure accurate reporting, please <Link className="inherit" onClick={()=>setCourseSearchOpen(true)}>specify the course</Link> based on the information provided if possible.
				</Box>}
				<Box py={2} >

					<ReportHeader report={report} role={role}/>

					{report.sections.map((section,i) => {
						return <Box key={i}>
							
							<Box pb={2}><Divider textAlign="left"><Typography variant="h6">{section.title}</Typography></Divider></Box>
							{section.blocks.map((block, j)=>{
								return <Box key={j} px={2} >
									
									<Box px={{xs:1, lg:2}} >
										{block.questions.map((question, k)=>{
											return <Box key={k} pb={4}>
												<Box sx={{fontWeight: 500}} className="primary">{question.prompt}</Box>
												<Box pl={0} sx={{color:"#666"}}><Answer all_answers={answers} question={question}/></Box>
												{/* <Box>{transform_answer(question)}</Box> */}
											</Box>;
										})}
									</Box>
								</Box>;
							})}
						</Box>;
					})}
				</Box>
			
		</Stack>
	</Stack> : <></>;
	

}