
import React from 'react';
import { Avatar, Box, Button, Stack, Link, FormControl, Select, InputLabel, MenuItem, TextField, InputAdornment, IconButton, OutlinedInput, Typography, Tooltip, Menu, Divider, MenuList, ListItem, ListItemIcon, Dialog, DialogActions } from '@mui/material';
import UserAvatar from './UserAvatar';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
import jax from '../helper/jax';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { ArrowDropDown, AssignmentTurnedIn, Block, Cancel, Check, Clear, ContentPaste, ContentPasteOff, MoreTime, Pending, PendingActions, StarBorderPurple500, Stars, TaskAlt, Upload } from '@mui/icons-material';
import MobileTooltip from './MobileTooptip';
import Icon from './Icon';
import { courseStatusMap } from '../helper/util';
import ProfileDialog from './ProfileDialog';
import { UserRoles } from '../admin/Personnel';
import { ListSubheader } from '@mui/material';

export default function Member(props) {
	const {member, courseId, outcomes, canEdit, isClosed, userIsAdmin, onChange, enrolling, courseRoles, isMe} = props;
	const [showComments, setShowComments] = React.useState(false);
	const [showUserProfile, setShowUserProfile] = React.useState(null);
	const [disapproving, setDisapproving] = React.useState(false);
	const [validRoles, setValidRoles] = React.useState([]);
	const {roleMap} = useSelector(state=>state.data);
	
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const setOutcome = async function(outcome_id) {
		onChange({outcome_id: outcome_id});
	}

	const makePrimary = async function() { 
		try {
			await jax.post(`/app/courses/${member.course_id}/primary_instructor`, {uid: member.uid});

			if (props.onMakePrimary) {
				props.onMakePrimary(member.uid);
			}
		} catch (err) {
			
		}
	}

	useEffect(()=>{
		var valid = courseRoles.map(r=>{
			return roleMap[r.role_id];
		});

		
		setValidRoles(valid);
	},[]);

	const approveAs = async function(role_id, reason) {
		
		if (role_id) {
			var result = await jax.post(`/app/courses/${member.course_id}/approve`, {uid: member.uid, role_id: role_id});
			onChange({...result, approved: true, comments: null, course_role_id: role_id}, true);
		} else {
			if (disapproving && reason) {
				var result = await jax.post(`/app/courses/${member.course_id}/disapprove`, {uid: member.uid, comments: reason});
				onChange({...result, approved: false}, true);
			} else {
				setDisapproving(true);
			}
		}
	}

	if (!canEdit && !member?.approved && !isMe) {
		return <></>;
	}

	return <Box p={2} py={3} flex={1} typography="body1" bgcolor={props.index % 2 == 1 ? '#f6f6f6' : ''} sx={{color: canEdit &&  !member.approved ? '#999' :''}}>
		<Stack direction={{xs:"column", lg:"row"}} justifyContent="space-between" alignItems="flex-start" spacing={2}>
			<Stack direction="row" spacing={2} flex={1} alignItems="center">
				<UserAvatar user={member} sx={{ bgcolor: '#999', width: 32, height: 32 }}/>
				<Stack sx={{flex:1}} >
					<Stack  direction="row" alignItems="center" spacing={0.5} sx={{lineHeight: 1}}><div ><Link onClick={()=>setShowUserProfile(member.uid)}>{member.title}</Link></div>{userIsAdmin && !!member.evaluated ? <MobileTooltip placement="top" disableFocusListener title="Evaluation Submitted"> <Box color={theme.palette.primary.main} ><AssignmentTurnedIn fontSize="small" ></AssignmentTurnedIn></Box></MobileTooltip> : userIsAdmin && <MobileTooltip placement="top" disableFocusListener title="Evaluation Pending"><Box color="#999999" ><ContentPasteOff fontSize="small"></ContentPasteOff></Box></MobileTooltip>}</Stack>
					<UserRoles user={member} size="small"/>
					
				</Stack>
				
				{isClosed && canEdit ? <Stack alignItems="flex-end" spacing={0.5}>
					{isClosed && userIsAdmin && member?.course_role_id != 4 && <Stack direction="row" alignItems="center">
						{/* <Icon name={courseStatusMap[gradStatus]?.icon} fontSize="small"  sx={{mr:0.5, color:courseStatusMap[gradStatus]?.color}}></Icon> <Typography lineHeight={1} color={courseStatusMap[gradStatus]?.color}>{courseStatusMap[gradStatus]?.label}</Typography> */}
						
						{/* {member.graduated && <>{member.distinction ? <Stars fontSize="small" sx={{mr:0.5}}></Stars> : <TaskAlt fontSize="small" sx={{mr:0.5}}></TaskAlt>} <b>Graduated</b></>}
						{member.distinction && <><b>&nbsp;w. Distinction</b></>}
						{!member.graduated && !member.withdrew && <><MoreTime fontSize="small" sx={{mr:0.5}}></MoreTime> <i>Add'l Training Required</i></>}
						{!member.graduated && member.withdrew && <><Block fontSize="small" color='error' sx={{mr:0.5}}></Block> <Typography lineHeight={1} color="error">Disenrolled</Typography></>} */}
						
					</Stack>}
				</Stack> : <></>}
			</Stack>

			{/* For non-primary instructors, show a button to make them primary */}
			{!!canEdit && (!!member.instructor && !member.primary) && !isClosed ? <Box alignSelf="flex-end" textAlign={{lg:"right", xs:"center"}} pt={{xs:1, lg:0}}>
				<Button size="small" variant="outlined" color="primary" sx={{mt:0, lineHeight: 0}} startIcon={<StarBorderPurple500/>} onClick={makePrimary}>Make Lead</Button>
			</Box> : <></>}

			{!!canEdit && (!!member.instructor && member.primary) && !isClosed ? <Stack direction="row" alignSelf="flex-end" alignItems="center" justifyContent="center" lineHeight={0} spacing={0.5} textAlign={{lg:"right", xs:"center"}} pt={{xs:1, lg:0}}>
				<Box><Stars/></Box>
				<Box>Lead Instructor</Box>
			</Stack> : <></>}

			{!!canEdit && !!enrolling && <Box alignSelf="flex-end" >
				<Button sx={{lineHeight: 0}} size="small" startIcon={member.approved ? <Check/> : <Block/>} variant="contained" color={member.approved ? "primary" : "error"} onClick={(e)=>setAnchorEl(e.currentTarget)} endIcon={<ArrowDropDown sx={{lineHeight: 0}} />}>{member.approved ? "Approved" : "Disapproved"}</Button>
				<Menu

					id="simple-menu"
					anchorEl={anchorEl}
					dense
					transformOrigin={{vertical: 'top', horizontal: 'right'}}
					anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
					open={Boolean(anchorEl)}
					slotProps={{paper: {sx:{pb: 1}}}}
					onClose={()=>setAnchorEl(null)}>
						<MenuList dense >
							
						{validRoles.length == 1 && <MenuItem onClick={()=>{setAnchorEl(null);approveAs(member.course_role_id)}}>Approve</MenuItem>}
						{validRoles.length > 1 && <Divider></Divider>}
						<ListSubheader>Approve as:</ListSubheader>
						{validRoles.length > 1 && validRoles.map((r,i)=><MenuItem  key={i} onClick={()=>{setAnchorEl(null);approveAs(r.id)}}>
							<ListItemIcon>
								{r.id == member.course_role_id && !!member.approved && <Check /> }
							</ListItemIcon>
								{r.name}
						</MenuItem>)}
						<Divider></Divider>
						<MenuItem onClick={()=>{setAnchorEl(null);approveAs(null)}}>Disapprove Enrollment</MenuItem>
					</MenuList>
				</Menu>
			</Box>}

			{!!canEdit && !member.instructor && !isClosed && !enrolling ? <Box alignSelf="flex-end">
					{!!outcomes.length && !!member.approved && <FormControl fullWidth sx={{  width:{lg:220}, mt: {xs:3, lg:0} }} size="small" required >
						<InputLabel id="enroll-label">Outcome</InputLabel>			
						<Select size="small" labelId="enroll-label" label="Outcome" value={member.outcome_id === null ? '' : member.outcome_id} required onChange={(e)=>setOutcome(e.target.value)}>
							<MenuItem value="">&nbsp;</MenuItem>
							{/* <MenuItem value="1">Graduate</MenuItem>
							{member.course_role_id == 1 && <MenuItem value="2">Graduate w/ Distinction</MenuItem>}
							<MenuItem value="0">Add'l Training Required</MenuItem>
							<MenuItem value="-1">Disenrolled</MenuItem> */}
							{outcomes?.map((o,i)=><MenuItem key={i} value={o.id}>{o.name}</MenuItem>)}
						</Select>
					</FormControl>}

					
				</Box> : <></>
			}

			{(!canEdit || isClosed) && <div >{member.primary && canEdit ? 'Lead ' : ''}{member.instructor ? 'Instructor' : member.course_role}</div> }

		</Stack>
		{!isClosed && canEdit && !member.instructor && (member.comments || showComments) ? <Box sx={{mt:2}}>
			<FormControl fullWidth sx={{  mt: {xs:0, lg:0} }} size="small"  >
				<InputLabel htmlFor="comments">Comments</InputLabel>
				<OutlinedInput fullWidth error={(!member.comments || !member.comments.trim())} label="Comments" size="small" value={member.comments} onChange={(e)=>onChange({comments:e.target.value})}
					endAdornment={<InputAdornment position="end" helperText="Visible only to Program Managers and Administrators">
							<IconButton onClick={()=>{setShowComments(false); onChange({comments:null})}} size="small" color="primary" aria-label="toggle comments" edge="end"><Clear></Clear></IconButton>
						</InputAdornment>}></OutlinedInput>
			</FormControl>
		</Box> : <></>}

		<ProfileDialog uid={showUserProfile} onClose={()=>setShowUserProfile(null)}>
		</ProfileDialog>

		{!isClosed && canEdit && !member.instructor && (!showComments) && (!enrolling || !member.approved) && !member.comments ? <Box sx={{mt:0}} textAlign="right">
			<Button size="small"   onClick={()=>setShowComments(true)}>+ Add Comments</Button>
		</Box> : <></>}
		<DisapproveDialog open={disapproving} onClose={()=>setDisapproving(false)} onDisapprove={(r)=>approveAs(null,r)}></DisapproveDialog>
	</Box>;
}

const DisapproveDialog = (props) => {
	const {open, onClose, onDisapprove} = props;
	const [reason, setReason] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const disapprove = async function() {
		setLoading(true);
		await onDisapprove(reason);
		setLoading(false);
		onClose();
	}

	return <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
		<Stack p={2} spacing={2} >
			<Box>
				<Typography variant="h6">Disapprove Enrollment</Typography>
			</Box>
			<Box>
				Please provide a reason for disapproval.  These comments will be provided to the disapproved participant.  Your contact email address will be provided to the participant in case they have questions.
			</Box>
			<Box pt={1}>
				<TextField fullWidth size="small" label="Reason for Disapproval" variant="outlined" required multiline rows={3} value={reason} onChange={(e)=>setReason(e.currentTarget.value)}></TextField>
			</Box>
		</Stack>
		<DialogActions>
			<Button size="small" onClick={onClose}>Cancel</Button>
			<Button size="small" onClick={disapprove} variant="contained" disabled={loading} color="error">Disapprove</Button>
		</DialogActions>
	</Dialog>
} 