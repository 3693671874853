import { useEffect, useRef, useState } from "react";
import jax from "../helper/jax";
import { Box, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, Divider, IconButton, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Stack, styled, TextField, Typography } from "@mui/material";
import { Article, AttachFile, CoPresent, CreateNewFolder, FileDownload, Folder, Image, KeyboardReturn, OpenInNew, Upload } from "@mui/icons-material";
import { set } from 'react-hook-form';
import { useEffectDebugger } from "../helper/util";
import { useTheme } from "@emotion/react";

const DragDropBox = styled('div')(({ theme }) => ({
	'&.over': {
		backgroundColor: theme.palette.success['pale'],
		border: `2px dashed ${theme.palette.success.main}`,
	}
}));

export const RootFolder = function(props) {
	const {UUID, name} = props;
}

export default function ContentExplorer(props) {
	const {basePath, homeFolderName, downloads, readOnly, minHeight, maxHeight, children} = props;
	
	const [path, setPath] = useState([]);
	const [files, setFiles] = useState([]);
	const [folders, setFolders] = useState([]);
	const [parent, setParent] = useState(null);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(null);
	const [uploading, setUploading] = useState([]);
	const [addLink, setAddLink] = useState(false);
	const [newLinkName, setNewLinkName] = useState('');
	const [newLinkUrl, setNewLinkUrl] = useState('');

	const theme = useTheme();
	// const [canDownload, setCanDownload] = useState(download);
	
	useEffect(()=>{
		if (path.length > 0) {
			setPath([]);
		}
	}, []);

	useEffect(()=>{
		setFiles([]);
		setFolders([]);
		if (basePath || path.length) {
			var pathStr = `${basePath ? basePath+'/' : ''}${path.join("/")}`;
			setLoading(true);
			// setCanDownload(homeFolderName.indexOf('Reference') != 0);
			jax.get(`/app/files/${pathStr}`, null, {no_feedback: true}).then((response)=>{
				setFiles(response.filter(x=>x.type != 'folder'));
				setFolders(response.filter(x=>x.type == 'folder'));
				//setParent(response.parent);
				setLoading(false);
			});
		}
	}, [path]);

	async function preview(path, contentType) {
		//var link = await jax.get(`/app/files/download/${path}?ct=${contentType}`);
		var pathStr = `/app/files/download/${basePath ? basePath+'/' : ''}${path}?ct=${contentType}`;
		console.log(pathStr);
		window.open(pathStr,'preview');
	}

	async function download(file) {
		setDownloading(file.id);
		var pathStr = `/app/files/download/${basePath ? basePath+'/' : ''}${path}?ct=${file.contentType}`;
		//var link = await jax.get(pathStr);
		console.log(pathStr);
		
		fetch(pathStr, {
			method: 'GET',
			headers: {
				'Content-Type': file.contentType,
			},
		}).then(response => response.blob())
			.then(blob => {
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				//Create a DOMString representing the blob and point the link element towards it
				let url = window.URL.createObjectURL(new Blob([blob]));
				a.href = url;
				a.download = file.name;
				//programatically click the link to trigger the download
				a.click();
				//release the reference to the file by revoking the Object URL
				window.URL.revokeObjectURL(url);
				setDownloading(null);
			});
	}

	function dropFile(e) {
		e.preventDefault();
		e.currentTarget.classList.remove('drop-target');
		console.log('drop')
		const droppedFiles = e.dataTransfer.files;
		
		uploadFiles(droppedFiles);
	}

	const uploadFiles = async (files) => {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append('file', files[i]);
		}
		var pathStr = `${basePath ? basePath+'/' : ''}${path.join("/")}`;

		var config = await jax.getConfig();
		config.method = 'POST';
		config.body = formData;
		delete config.headers['Content-Type'];// = 'multipart/form-data';
		fetch(`/app/files/${pathStr}`, config)
		.then(response => response.json())
		.then(data => {
			setFiles(data.filter(x=>x.type != 'folder'));
			setFolders(data.filter(x=>x.type == 'folder'));
		})
		.catch(error => {
			console.error(error);
		});
		

		// jax.post(`/app/files/upload/${pathStr}`, formData, {no_feedback: true}).then((response)=>{
		// 	setFiles(response.filter(x=>x.type != 'folder'));
		// 	setFolders(response.filter(x=>x.type == 'folder'));
		// }).catch(err=>{
		// 	console.error(err);
		// });
	}

	const createFolder = async () => {
		var pathStr = `${basePath ? basePath+'/' : ''}${path.join("/")}/test`;
		jax.post(`/app/files/create/${pathStr}`, null, {no_feedback: true}).then((response)=>{
			// setFiles(response.filter(x=>x.type != 'folder'));
			// setFolders(response.filter(x=>x.type == 'folder'));
		}).catch(err=>{
			console.error(err);
		});
	}

	const handleFileChange = (e) => {
		setUploading([...uploading, e.target.files]);
		uploadFiles(e.target.files);
	}

	const selectFiles = () => {
		inputRef.current.click();
	}

	const createLink = () => {
		var pathStr = `${basePath ? basePath+'/' : ''}${path.join("/")}`;
		jax.post(`/app/files/link/${pathStr}`, {name: newLinkName, url: newLinkUrl}, {no_feedback: true}).then((response)=>{
			
		}).catch(err=>{
			console.error(err);
		});
	}

	const fileTypes = {
		"application/pdf": <Article color="error"/>,
		"image/jpeg": <Image color="info"/>, 
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": <CoPresent color="info" mr={1}/>,
		"link": <OpenInNew color="info"/>,
	}

	const inputRef = useRef(null);

	return <Box > 
		{(path.length || !readOnly) && <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} p={1}>
			<Box flex={1}>
				<Breadcrumbs >
					{path.length && <Link sx={{cursor:"pointer"}} onClick={()=>{setPath([])}}>{homeFolderName || "Resources"}</Link>}
					{path.map((p,i)=>{
						var text = p;
						if (i == 0 && children?.length) {
							var c = children.find(x=>x.props.path == path[i]);
							text = c.props.name;
						}
						return i == path.length-1  ? <Typography key={i}>{text}</Typography> : <Link key={i} sx={{cursor:"pointer"}} onClick={()=>{setPath(path.slice(0, i+1))}}>{text}</Link>
					})}
				</Breadcrumbs>
			</Box>
			{!readOnly && <>
				<Button size="small" variant="contained" color="primary" onClick={selectFiles} startIcon={<Upload/>}>Upload File(s)</Button>
				<Button size="small" variant="outlined" color="info" onClick={()=>setAddLink(true)} startIcon={<OpenInNew/>}>Add Link</Button>
				<Button size="small" variant="outlined" color="warning" onClick={createFolder} startIcon={<CreateNewFolder/>}>New Folder</Button>
			</>}
		</Stack>}
		
		<Divider/>
		<DragDropBox onDrop={dropFile} onDragOver={(e)=>e.preventDefault()} onDragEnter={(e)=>{e.preventDefault(); e.currentTarget.classList.add('over');}} onDragLeave={(e)=>{e.preventDefault(); e.currentTarget.classList.remove('over');}}>
			<Box minHeight={minHeight} maxHeight={maxHeight} sx={{overflowY: 'auto'}}>
				{loading ? <Box p={4}><LinearProgress></LinearProgress></Box> : <List>
					
					{!basePath && !path?.length && children.map((folder, i)=>{
						return <ListItem disablePadding key={i}>  
							<ListItemButton onClick={()=>{setPath([folder.props.path])}} alignItems="flex-start">
								<ListItemAvatar sx={{minWidth:0, mr:0.75, mt:0.5}}><Folder color="warning"></Folder></ListItemAvatar>
								<ListItemText primary={folder.props.name}></ListItemText>
							</ListItemButton>
						</ListItem>
					})}

					{folders.map((folder, i)=>{
						return <ListItem disablePadding key={i}>  
							<ListItemButton onClick={()=>{setPath([...path, folder.name])}} alignItems="flex-start">
								<ListItemAvatar sx={{minWidth:0, mr:0.75, mt:0.5}}><Folder color="warning"></Folder></ListItemAvatar>
								<ListItemText primary={folder.name}></ListItemText>
							</ListItemButton>
						</ListItem>
					})}

					{files.map((file, i)=>{
						return <ListItem disablePadding key={i} secondaryAction={
							!!file.downloadUrl && downloads && <IconButton edge="end" size="small" aria-label="comments" onClick={()=>{download(file)}}>
								{downloading == file.id ? <CircularProgress size="24px" /> : <FileDownload />}
							</IconButton>
							}> 
							<ListItemButton onClick={()=>{preview([...path, file.name].join('/'), file.type)}} alignItems="flex-start">
								<ListItemAvatar sx={{minWidth:0, mr:0.75, mt:0.5}}>{fileTypes[file.type] || <AttachFile/>}</ListItemAvatar>
								<ListItemText sx={{mt:0.5, textDecoration: file.type =='link' ? 'underline' : '', color: file.type == 'link' ? theme.palette.info.main : ''}} primary={file.name} secondary={file.subtitle}></ListItemText>
							</ListItemButton>
						</ListItem>
					})}
				
				</List>}
			</Box>

			<input
				type="file"
				hidden
				id="upload"
				onChange={handleFileChange}
				accept=".pdf,.docx,.pptx,.txt,.xlsx"
				multiple
				ref={inputRef}
			/>
		</DragDropBox>

		<Dialog  open={addLink} fullWidth maxWidth="xs" onClose={()=>setAddLink(false)} PaperProps={{component:'form', onSubmit: createLink}}>
			<Stack p={2} spacing={2} >
				<Box>
					<TextField fullWidth defaultValue={newLinkName} onChange={(e)=>setNewLinkName(e.currentTarget.value)} size="small" label="Name" variant="outlined" required></TextField>
				</Box>
				<Box>
					<TextField fullWidth defaultValue={newLinkUrl} onChange={(e)=>setNewLinkUrl(e.currentTarget.value)} size="small" label="URL" variant="outlined" required></TextField>
				</Box>
			</Stack>
			<DialogActions>
				<Button onClick={()=>setAddLink(false)}>Cancel</Button>
				<Button type="submit">Add Link</Button>
			</DialogActions>
		</Dialog>
	</Box>
}