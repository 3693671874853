import { Box, Typography, Stack, FormControlLabel, Table, Divider, TableBody, TableHead, Radio, TextField, TableCell, TableRow, RadioGroup, useMediaQuery, Checkbox, styled, Button, FormControl, Select, MenuItem, InputLabel, Card, Backdrop, IconButton, Fab, ListItemIcon, ListItemText } from "@mui/material";
import {useForm, Controller} from 'react-hook-form';
import {Unstable_NumberInput as NumberInput} from '@mui/base/Unstable_NumberInput';
import { set } from 'react-hook-form';
import { useEffect, useState } from "react";
import { evaluate, short_id } from "../../helper/util";
import { Add, ArrowDownward, ArrowUpward, CalendarMonth, CheckBox, CheckBoxOutlineBlank, Checklist, Delete, Edit, EditNote, FormatListBulleted, Info, InfoOutlined, Numbers, RadioButtonChecked, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import Notification  from "../Notification";
import { useTheme } from "@emotion/react";



const SectionStack = styled(Stack)(({ theme }) => ({
	
	'&.editing': {
		'& .editable': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
			textDecorationStyle: 'dashed',
			textDecorationThickness: '1px',
			textUnderlineOffset: '2px',
			cursor: 'pointer',
			'&:hover': {
				color: theme.palette.primary.dark
			}
		},
		'& .eval-block': {
			background: "#fcfcfc",
			borderRadius: theme.shape.borderRadius,
			border: `2px dashed ${theme.palette.grey.light}`,
			'& .eval-block-header': {
				transform: 'translateY(-50%)',
			}
		},
		'&[data-question-index]': {
			borderBottom: 'dotted 1px grey'
		}
	}
})); 

export const NewItemBox = styled(Box)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	border: `1px dashed ${theme.palette.primary.main}`,
	padding: '8px',
	backgroundColor: theme.palette.primary.ultraLight,
	fontWeight: 'bold',
	fontSize: '1rem',
	cursor: 'pointer',
	color: theme.palette.primary.main,
	'&.editable': {
		textDecoration: 'none !important'
	},
	'&:hover': {
		backgroundColor: theme.palette.primary.lighter
	},
	'&.tight': {
		padding: '4px 8px',
		fontSize: '0.9rem'
	}
}));

export default function(props) {

	const {section, invalid, answers, onAnswer, canEdit, onEditing, onCancel, onBlocksUpdate, onDelete, is_lead, editing, editMode, ...other} = props;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [blocks, setBlocks] = useState([]);
	
	const [editEl, setEditEl] = useState(null);
	const [editProps, setEditProps] = useState({});

	const [hovered, setHovered] = useState(false);
	const theme = useTheme();

	useEffect(()=>{
		//Deep copy (to support canceling edits)
		setBlocks(JSON.parse(JSON.stringify(section.blocks)));
	}, [section, editing])

	const filterBlocks = () => {			
		var blks = section.blocks.filter(b=>{
			return editMode || !b.condition || evaluate(b.condition, { ...answers, _course_code: answers?.course_code, _role: answers?.role_id, _is_lead: is_lead});
		})
		setBlocks(blks);
	}

	const recordAnswer = (e) => {	
		var block_id = e.target.closest('[block]').getAttribute('block');
		var ans = e.target.value;
		if (e.target.type == 'radio' && !isNaN(ans)) {
			ans = parseInt(ans);
		} else if (e.target.type == 'checkbox') {
			var val = e.target.value;
			var current = (answers[block_id][e.target.name] || '').split('|');
			if (e.target.checked) {
				current.push(val);
			} else {
				current = current.filter(x=>x!=val);
			}
			ans = current.join('|');
		}

		if (onAnswer)	{
			onAnswer({schema_id: section.eval_schema_id, block_id: block_id, answer_id: e.target.name, answer: ans})
		}
	}

	useEffect(()=>{
		if (!editMode) {
			filterBlocks();
		}
	}, [answers, section]);


	//function to move an item in an array to a new index
	const moveItem = (arr, from, to) => {
		if (to !== 0 && !to) {
			return arr;
		}
		var el = arr.splice(from, 1)[0];
		arr.splice(to, 0, el);
		return arr.map(i=>i);
	}

	const moveBlockDown = (i) => {
		setBlocks(moveItem(blocks, i, i+1));
	}

	const moveBlockUp = (i) => {
		setBlocks(moveItem(blocks, i, i-1));
	}

	const removeItem = (e) => {
		e.preventDefault();
		
		var block = blocks[editProps?.block_index];
		if (!block) { //No block == removing a block
			var block_index = e.currentTarget.closest('[data-block-index')?.getAttribute('data-block-index');
			blocks.splice(block_index, 1);
		} else if (editProps.item_type == 'likert_rating') {
			var labels = block.likert_labels?.split('|') || likert;
			labels.splice(editProps.order, 1);
			block.likert_labels = labels.join('|');
		} else if (editProps.item_type == 'metric') {
			block.questions.splice(editProps.order, 1);
		
		} else if (editProps.item_type == 'choice') {
			block.questions[editProps.question_index].choices.splice(editProps.order, 1);
		} else if (editProps.question_index) {
			//remove question by index
			block.questions.splice(editProps.question_index, 1);			
		}
		
		setBlocks(blocks);
		setEditProps({});
		setEditEl(null);
	}

	const addLikertMetric = () => {
		var block = blocks[editProps.block_index];
		var metric = {prompt: editProps.prompt, label: editProps.label, question_id: short_id()};

		//insert metric at index
		block.questions.splice(editProps.new_order, 0, metric);
	}

	const addLikertColumn = () => {
		var block = blocks[editProps.block_index];
		var labels = block.likert_labels?.split('|') || likert;

		//insert label at index
		labels.splice(editProps.new_order, 0, editProps.prompt);
		block.likert_labels = labels.join('|');
	}

	//Used in editor when clicking an editable property
	const beginEditing = (e) => {
		var editable = e.target.closest('.editable');
		if (editing && editable) {
			var block_index = editable.closest('[data-block-index')?.getAttribute('data-block-index');
			var question_index = editable.closest('[data-question-index')?.getAttribute('data-question-index');
			var question_id = editable.closest('[data-question-index')?.getAttribute('data-question-id');
			var property = editable.getAttribute('edit_property');
			var sub_property = editable.getAttribute('edit_sub_property');
			var prop_type = editable.getAttribute('edit_prop_type');
			var order = editable.getAttribute('edit_order');
			var items = editable.getAttribute('edit_items');
			var new_item = editable.getAttribute('edit_new_item');
			var label = editable.getAttribute('edit_label');

			var props = {
				block_index: block_index,
				block_id: editable.closest('[data-block-index')?.getAttribute('block'),
				label: label,
				question_index: question_index,
				question_id: question_id,
				property: property,
				sub_property: sub_property,
				item_type: prop_type,
				block_type: 'mixed',
				prompt: new_item == "1" ? "" : e.target.innerText,
				order: order,
				new_order: order,
				items: items,
				new_item: new_item
			}

			switch (props.item_type) {
				case "block":
					props.promptLabel = "Introduction / Instructions";
					break;
				case "metric":
					props.promptLabel = "Question / Prompt";
					break;
				case "likert":
					if (new_item != "1") {
						var b = blocks[block_index];
						props = {...props, ...b};
					}
					props.promptLabel = "Question / Prompt";
					break;
				case "likert_rating":
					props.promptLabel = "Rating Label";
					break;
				case "question":
					if (new_item != "1") {
						var q = blocks[block_index].questions[order];
						props = {...props, ...q};
					} else {
						props.type = "text";
					}
					
					props.promptLabel = "Question / Prompt";
					break;
			}

			setEditProps(props);
			setEditEl(e.target);
		}
	}

	const saveEditProps = (e) => {
		e.preventDefault();
		var block = blocks[editProps.block_index];
		try {
			if (editProps.item_type == 'likert_rating') {
				if (editProps.new_item == "1") {
					addLikertColumn();
				} else {
					var labels = block.likert_labels?.split('|') || likert;
					labels[editProps.order] = editProps.prompt;
					//reorder labels based on order and new value
					if (editProps.new_order > -1 && editProps.new_order != editProps.order) {
						moveItem(labels, editProps.order, editProps.new_order)
					}					
					block.likert_labels = labels.join('|');
				}
			} else if (editProps.item_type == 'metric') {
				if (editProps.new_item == "1") {
					addLikertMetric();
				} else {
					block.questions[editProps.order].prompt = editProps.prompt;
					block.questions[editProps.order].label = editProps.label;
					if (editProps.new_order > -1 && editProps.new_order != editProps.order) {
						moveItem(block.questions, editProps.order, editProps.new_order);
					}
				}
				
			} else if (editProps.item_type =='block' || editProps.item_type == 'likert') {
				if (editProps.new_item == "1") {
					//Add new block
					var new_block = {block_id: short_id(), eval_section_id: section.id, label: editProps.label, required: editProps.required, prompt: editProps.prompt, type: editProps.block_type, questions: []};
					blocks.push(new_block);
					moveItem(blocks, blocks.length-1, editProps.new_order);
				} else {
					block.prompt = editProps.prompt;
					block.label = editProps.label;
					block.required = editProps.required;
					if (editProps.new_order > -1 && editProps.new_order != editProps.order) {
						moveItem(blocks, editProps.block_index, editProps.new_order);
					}
				}
			} else if (editProps.item_type == 'question') {
				if (editProps.new_item == "1") {
					//Add new question
					var new_question = {prompt: editProps.prompt, label: editProps.label, question_id: short_id(), type: editProps.type, answer_id: `Q${block.questions.length+1}`};
					if (editProps.type == "radio" || editProps.type == "multi-select") {
						new_question.choices = [{code: "Sample", label: "Sample Choice"}];
						new_question.allow_other = editProps.allow_other;
					}
					block.questions.push(new_question);
					moveItem(block.questions, block.questions.length-1, editProps.new_order);
				} else {
					block.questions[editProps.order].prompt = editProps.prompt;
					block.questions[editProps.order].label = editProps.label;
					block.questions[editProps.order].required = editProps.required || false;
					if (editProps.new_order > -1 && editProps.new_order != editProps.order) {
						moveItem(block.questions, editProps.order, editProps.new_order);
					}
				}
			} else if (editProps.item_type == 'choice') {
				if (editProps.new_item == "1") {
					var q = block.questions[editProps.question_index];
					q.choices.push({label: editProps.prompt, code: editProps.label});
					moveItem(q.choices, q.choices.length-1, editProps.new_order);
				} else {
					block.questions[editProps.question_index].choices[editProps.order].label = editProps.prompt;
					block.questions[editProps.question_index].choices[editProps.order].code = editProps.label;
					if (editProps.new_order > -1 && editProps.new_order != editProps.order) {
						moveItem(block.questions[editProps.question_index].choices, editProps.order, editProps.new_order);
					}
				}
			}else {
				if (block?.type == "likert" && editProps.new_item == "1") {
					
				} else if (editProps.new_item == "1") {
				
				} else {
					block.prompt = editProps.prompt; 
				}
			}
		} finally {
			setBlocks(blocks);
		
			setEditProps({});
			setEditEl(null);
		}

	}



	var likert = ["Poor", "Needs Attention", "Average", "Good", "Excellent"];
	return section && (answers || editMode) ? <Box position="relative" {...other} onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}>
		
		<SectionStack direction="column" spacing={2}  pb={0} className={editing ? "editing" : ""} onClick={beginEditing} >
		
		{editMode && <Stack direction="row" alignItems="center" p={1} sx={{backgroundColor: editing ? theme.palette.primary.main : "#ccc"}}>
					<Box flex={1} ><Typography variant="h6" color={editing ? "#fff" : "black"}  >Section {section.sequence+1}: {section.title}</Typography></Box>
					{canEdit && !editing && hovered && <Stack direction="row" spacing={1}>
						<Button size="small" variant="contained" onClick={()=>onEditing(section.id)}>Edit Section</Button>
						<Button size="small" variant="contained" color="error" onClick={()=>onDelete(section.id)} startIcon={<Delete/>}>Delete Section</Button>
					</Stack>}
					{editing && <Stack direction="row" spacing={2}>
						<Button size="small" color="white" variant="outlined" onClick={()=>{setBlocks(section.blocks); onCancel();}}>Cancel</Button>
						<Button size="small" color="white" variant="contained" sx={{color: theme.palette.primary.main}} onClick={()=>onBlocksUpdate(blocks)}>Apply Changes</Button>
					</Stack>}
				</Stack>}
		<Stack p={editing ? 2 : 0} spacing={editing ? 8 : 2}>
			{blocks.map((block, i) => {
				if (block.eval_section_id != section.id) {
					return <></>;
				}
				return <Box key={i} className="eval-block" block={block.block_id} data-block-index={i} position="relative">
					
					{editing && <Stack direction="row" alignItems="center" justifyContent="center" className="eval-block-header">
						<Card variant="outlined" sx={{padding: '0px'}}>
							<Stack direction="row" alignItems="center" spacing={0}>
								<Button >{block.label}</Button>
								<Divider orientation="vertical" flexItem />
								{i < blocks.length-1 && <Button sx={{minWidth:0}} onClick={()=>moveBlockDown(i)}><ArrowDownward /></Button>}
								{i > 0 && <Button onClick={()=>moveBlockUp(i)} sx={{minWidth:0}}><ArrowUpward /></Button>}
								<Button onClick={(e)=>{setEditProps({type:'block', block_index: i}); removeItem(e);}} sx={{minWidth:0}} color="error"><Delete /></Button>
							</Stack>
						</Card>
					</Stack>}
					
					{/* {i > 0 &&<Divider sx={{mb:2}}></Divider>} */}
					 
					{block.instructions}

					{block.type == "likert" && <Box pt={1}>
						{isMobile && <Box p={2} pt={0}><Typography ml={1} className="prompt editable" edit_prop_type="block">{block.prompt}</Typography></Box>}
						
						<Table className="likert-table" size="small">

							{/* LIKERT COLUMNS (NON-MOBILE) */}
							{!isMobile && <TableHead sx={{verticalAlign:"bottom"}}>
								<TableRow >
									<TableCell className="editable" verticalAlign="bottom" sx={{verticalAlign:"bottom"}}>
										{block.prompt && <Box sx={{fontWeight: 'bold', px:0}}>
										<Typography ml={1} fontWeight="bold" className="prompt editable" edit_prop_type="likert" edit_order={i} edit_label={block.label} edit_items={blocks.length}>{block.prompt}</Typography>
										</Box>}
									</TableCell> 
									{(block.likert_labels?.split('|') || likert).map((label, j) => {
										return !isMobile && <TableCell width="10%" key={j} align="center" sx={{verticalAlign:"bottom"}} className="editable" edit_prop_type="likert_rating" edit_order={j} edit_sub_property={j} edit_items={(block.likert_labels?.split('|') || likert).length}>{label}</TableCell>;
									})}
									{editing && <TableCell width="10%" align="center" sx={{verticalAlign:"bottom"}} ><NewItemBox className="editable" edit_new_item="1" edit_order={(block.likert_labels?.split('|') || likert).length} edit_items={(block.likert_labels?.split('|') || likert).length+1} edit_prop_type="likert_rating">+ Add</NewItemBox></TableCell>}
								</TableRow>

							</TableHead>}

							{/* LIKERT METRICS */}
							<TableBody>
									
								{block.questions.map((question, j) => {
									return <TableRow key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] ? 'invalid-answer' : ''} data-question-index={j} data-question-id={question.question_id} sx={{backgroundColor: j % 2 ?'#f6f6f6' : ''}}>
										<TableCell>
											<Typography sx={{pl:1}} fontSize="0.9rem" className="editable" edit_prop_type="metric" edit_label={question.label} edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>
											{isMobile && <Box > 
											
												{(block.likert_labels?.split('|') || likert).map((label, k) => {
													return <Box key={k} sx={{display:"flex", alignItems:"center", justifyContent:"flex-start", py:0}}>
														<FormControlLabel control={<Radio
															checked={!editMode && answers[block.block_id][question.answer_id] == k+1}
															onChange={recordAnswer}
															value={k+1}
															name={question.answer_id}
															sx={{py:0.5, pl:6, pr:1}}
														/>} 
													label={label} />
													
												</Box>;
											})}</Box>}
										</TableCell>
										
										{!isMobile && (block.likert_labels?.split('|') || likert).map((label, k) => {
											
											return <TableCell key={k} align="center">
												<Radio
													disabled={editMode}
													checked={!editMode && answers[block.block_id][question.answer_id] == k+1}
													onChange={recordAnswer}
													value={k+1}
													name={question.answer_id}
												/>
											</TableCell>;
										})}	

										{editing && <TableCell align="center"></TableCell>}
										
									</TableRow>
								})}

								{/* EDIT MODE: ADD LIKERT METRIC */}

								{editMode && editing && <TableRow className="editable" sx={{'& td': {backgroundColor: `${theme.palette.primary.ultraLight} !important`}, '&:hover td': {cursor: 'pointer', backgroundColor: `${theme.palette.primary.lighter} !important`}}} edit_new_item="1" edit_prop_type="metric" edit_order={block.questions.length} edit_items={block.questions.length+1}>
										<TableCell  >
											<Box pl={1} color={theme.palette.primary.main}>+ New Metric</Box>
										</TableCell>
										{(block.likert_labels?.split('|') || likert).map((label, k) => {
											
											return <TableCell key={k} align="center">
												<Radio
													disabled={true}
													checked={false}
													value={k+1}
													sx={{opacity: 0.75}}
												/>
											</TableCell>;
										})}
										<TableCell></TableCell>
									</TableRow>}

							</TableBody>
						</Table>
					</Box>}
					
					{block.type != "likert" && <Stack direction="column" spacing={1} alignItems="flex-start">
						{block.questions.map((question, j) => {
							return <Box py={1.5} mt="0 !important" key={question.id} data-question-id={question.question_id} data-question-index={j} alignSelf="stretch">
								{question.type=="multiline" && <Box sx={{px:2}} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									{question.prompt && <Typography className="editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
									<TextField multiline disabled={editMode} error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"}  rows={4} sx={{width: "100%", mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
								</Box>}

								{question.type == "date" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
											{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
											<TextField  disabled={editMode} size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} type="date" sx={{width: 180, mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
										</Box>
								</Stack>}

								{question.type == "text" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
											{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
											<TextField disabled={editMode} size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} sx={{width: "100%", mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
										</Box>
								</Stack>}

								{question.type == "number" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
											{question.prompt && <Typography className="prompt editable " edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
											{/* <NumberInput disabled={editMode} size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} sx={{width: 90, mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></NumberInput> */}
											<TextField disabled={editMode} size="small" type="number" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} inputProps={{"type": "number"}} sx={{width: 90, mt:1}} name={question.answer_id} value={editMode ? "" : answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
										</Box>
								</Stack>}

								{question.type == "radio" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box  alignSelf="flex-start" >
										{question.prompt &&<Box sx={{px:2}} >
											<Typography className="prompt editable" edit_prop_type="question" edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>
										</Box>}

										<Box sx={{px:2, pl:4, mt: '0 !important'}} mt={0} >
											<Stack direction="column" className="radio" alignItems="flex-start">
												{question.choices.map((choice, k) => {
													return <FormControlLabel key={k} sx={{mt:1}} className="editable" disableTypography edit_label={choice.code} edit_prop_type="choice" edit_order={k} edit_items={question.choices.length} control={<Radio
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id] == choice.code}
														onChange={recordAnswer}
														value={choice.code}
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label={choice.label} />
												})}

												{editing && <NewItemBox sx={{width:"100%"}} ml={"-11px"} mt={1} py={1} display="flex" alignItems="center" className="editable tight" edit_prop_type="choice" edit_new_item="1" edit_items={question.choices.length+1} edit_order={question.choices.length} >
													<RadioButtonUncheckedOutlined sx={{mr:1}}/> New Choice
												</NewItemBox>}

												{!!question.allow_other && <Stack direction="row" sx={{mt:1}} >
													<FormControlLabel  control={<Radio
														disabled={editMode}
															checked={!editMode && answers[block.block_id][question.answer_id] == "OTHER"}
															onChange={recordAnswer}
															value="OTHER"
															name={question.answer_id}
															sx={{py:0.5, pr:1}}
														/>} label="Other" />
														{!editMode && answers[block.block_id][question.answer_id]?.split('|').includes("OTHER") &&
															<TextField disabled={editMode} size="small" className="other-text" sx={{mt: 1, width: 90, mt:1}} name={`${question.answer_id}_OTHER`} value={!editMode && answers[block.block_id][question.answer_id+'_OTHER']} onChange={recordAnswer}></TextField>
														}
													</Stack>}
												
											</Stack>
										</Box>
									</Box>
								</Stack>}

								{(question.type == "multi-select") && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} alignSelf="flex-start" >
										{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_label={question.label} edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
										<Box sx={{px:2, pl:2}} >
											<Stack direction="column" className="checkbox" alignItems="flex-start">
												{question.choices.map((choice, k) => {
													return <FormControlLabel sx={{mt:1}} key={k} className="editable" disableTypography edit_label={choice.code} edit_prop_type="choice" edit_order={k} edit_items={question.choices.length} control={<Checkbox
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id]?.split('|').includes(choice.code)}
														onChange={recordAnswer}
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
														inputProps={{value: choice.code}}
													/>} label={choice.label} />
												})}

												{editing && <NewItemBox sx={{width:"100%"}} ml={"-11px"} mt={1} py={1} display="flex" alignItems="center" className="editable tight" edit_prop_type="choice" edit_new_item="1" edit_items={question.choices.length+1} edit_order={question.choices.length} >
													<CheckBoxOutlineBlank sx={{mr:1}}/> New Choice
												</NewItemBox>}

												{!!question.allow_other && <Stack sx={{mt:1}} direction="row">
													<FormControlLabel control={<Checkbox
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id]?.split('|').includes("OTHER")}
														onChange={recordAnswer}
														inputProps={{value: "OTHER"}}
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label="Other" />
													{!editMode && answers[block.block_id][question.answer_id]?.split('|').includes("OTHER") &&
														<TextField size="small" disabled={editMode} className="other-text" placeholder="Please describe..." sx={{width: 90, mt:1}} name={`${question.answer_id}_OTHER`} value={!editMode && answers[block.block_id][question.answer_id+'_OTHER']} onChange={recordAnswer}></TextField>
													}
												</Stack>}
											</Stack>
										</Box>
									</Box>
								</Stack>}

								{(question.type == "yes_no" || question.type == "yes-no") && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
									<Box sx={{px:2}} >
										{question.prompt && <Typography className="prompt editable" edit_prop_type="question" edit_label={question.label} edit_order={j} edit_items={block.questions.length}>{question.prompt}</Typography>}
										<Box sx={{px:2, pl:2}} >
											<Stack direction="row" className="yes-no" alignItems="flex-start">													
												<FormControlLabel control={<Radio
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id] == "Yes"}
														onChange={recordAnswer}
														value="Yes"
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label="Yes" />
												<FormControlLabel control={<Radio
														disabled={editMode}
														checked={!editMode && answers[block.block_id][question.answer_id] == "No"}
														onChange={recordAnswer}
														value="No"
														name={question.answer_id}
														sx={{py:0.5, pr:1}}
													/>} label="No" />
											</Stack>
											
										</Box>
									</Box>
								</Stack>}

								
							</Box>;
						})}

						{editing && <Box px={2} alignSelf="stretch" pb={2}><NewItemBox  textAlign="center" className="editable" edit_prop_type="question" edit_new_item="1" edit_items={block.questions.length+1} edit_order={block.questions.length} >
							<Divider  textAlign="center" variant="middle" >+ New Question</Divider>
						</NewItemBox></Box>}
					</Stack>}

				</Box>
			})}
			
			{editMode && editing && <NewItemBox className="editable" sx={{p:3, mt:2}} edit_new_item="1" edit_items={blocks.length+1} edit_prop_type="block" ><Divider  textAlign="center" variant="middle" >+ New Block</Divider></NewItemBox>}

		</Stack>


		

		{editMode && <Notification open={!!editEl} anchorEl={editEl} onClose={()=>setEditEl(null)} minWidth={400} maxWidth={600} background="#fff" color="#000" over={true} >
			<form onSubmit={saveEditProps}>
				<Stack direction="column" spacing={2} pt={1}>

					{editProps.item_type == "question" && editProps.new_item == "1" && <FormControl>
						<InputLabel size="small" id="qtype-label">Question Type</InputLabel>
						<Select MenuProps={{dense:true}} sx={{'& .MuiSelect-select': {display:'flex', alignItems: 'center'}, '& .MuiListItemText-root': {marginTop: 0, marginBottom: 0}}} size="small" name="order" labelId="qtype-label" defaultValue={editProps.type} onChange={(e)=>setEditProps({...editProps, type: e.target.value})} fullWidth label="Question Type" variant="outlined">
							<MenuItem value="text" prepend>
								<ListItemIcon >
									<Edit />
								</ListItemIcon>
								<ListItemText>
									Text
								</ListItemText>
							</MenuItem>
							<MenuItem value="multiline">
								<ListItemIcon>
									<EditNote />
								</ListItemIcon>
								<ListItemText>
									Multi-line text
								</ListItemText>
							</MenuItem>
							<MenuItem value="number">
								<ListItemIcon>
									<Numbers />
								</ListItemIcon>
								<ListItemText>
									Number
								</ListItemText>
							</MenuItem>
							<MenuItem value="date">
								<ListItemIcon>
									<CalendarMonth />
								</ListItemIcon>
								<ListItemText>
									Date
								</ListItemText>
							</MenuItem>
							<MenuItem value="yes_no">
								<ListItemIcon>
									<CheckBox/>
								</ListItemIcon>
								<ListItemText>
									Yes / No
								</ListItemText>
							</MenuItem>
							<MenuItem value="radio">
								<ListItemIcon>
									<FormatListBulleted />
								</ListItemIcon>
								<ListItemText>
									Single-selection List
								</ListItemText>
							</MenuItem>
							<MenuItem value="multi-select">
								<ListItemIcon>
									<Checklist />
								</ListItemIcon>
								<ListItemText>
									Multiple-selection list
								</ListItemText>
							</MenuItem>
						</Select>
					</FormControl>}

					

					<Box flex={1}>
						<TextField size="small" name="prompt" defaultValue={editProps.prompt} onChange={(e)=>setEditProps({...editProps, prompt: e.currentTarget.value})} autoFocus fullWidth label={editProps.promptLabel} variant="outlined" />
					</Box>
						

					{["block", "choice", "question", "metric"].includes(editProps.item_type) && <FormControl>
						<TextField size="small" name="name" label="Reporting Label" defaultValue={editProps.label} onChange={(e)=>setEditProps({...editProps, label: e.currentTarget.value})} fullWidth variant="outlined" helperText="This label is used to identify this element in reports" />	
					</FormControl>}

					

					{editProps.new_item && editProps.item_type == "block" && <FormControl>
						<InputLabel id="block-type-label">Type</InputLabel>
						<Select size="small" name="type" defaultValue={editProps.block_type} onChange={(e)=>setEditProps({...editProps, block_type: e.target.value})} fullWidth label="Type" variant="outlined">
							<MenuItem value="likert">Likert Scale</MenuItem>
							<MenuItem value="mixed">Questions</MenuItem>
						</Select>
					</FormControl>}

					{["metric", "likert_rating", "block", "question", "choice"].includes(editProps.item_type) && <FormControl>
						<FormControl>
							<InputLabel size="small" id="order-label">Order</InputLabel>
							<Select size="small" name="order" labelId="order-label" defaultValue={editProps.order} onChange={(e)=>setEditProps({...editProps, new_order: e.target.value})} fullWidth label="Order" variant="outlined">
								{Array.from({length: editProps.items}).map((x, i) => {
									return <MenuItem key={i} value={i}>{i+1}</MenuItem>
								})}
							</Select>
						</FormControl>
					</FormControl>}

					

					{["radio","multi-select"].includes(editProps.type) && <FormControl sx={{paddingLeft: 1, '& .MuiCheckbox-root':{paddingTop:'0 !important', paddingBottom:0}}}>
						<FormControlLabel label="Allow freeform entry (Other)"  control={<Checkbox size="small" name="allow_other" checked={editProps.allow_other} onChange={(e)=>setEditProps({...editProps, allow_other: e.target.checked})} />}></FormControlLabel>
					</FormControl>}

					{["question", "likert"].includes(editProps.item_type) && <FormControl sx={{paddingLeft: 1, '& .MuiCheckbox-root':{paddingTop:'0 !important', paddingBottom:0}}}>
						<FormControlLabel label="Require a Response" control={<Checkbox size="small" name="required" checked={editProps.required} onChange={(e)=>setEditProps({...editProps, required: e.target.checked})} />}></FormControlLabel>
					</FormControl>}

					<Box textAlign="right" class="sub-title xs">
						<Box>
							Block ID: {editProps.block_id}
						</Box>
						<Box>
							Question ID: {editProps.question_id}
						</Box>
					</Box>
					

					<Stack direction="row" spacing={2} >
						<Box flex={1}>
							<Button size="small" onClick={()=>setEditEl(null)} color="disabled">Cancel</Button>
						</Box>
						{editProps.new_item !== "1" && <Button size="small" variant="contained" color="error" startIcon={<Delete/>} onClick={removeItem}>Delete</Button>}
						<Button size="small" type="submit" variant="contained" color="primary">Save</Button>
					</Stack>
				</Stack>


			</form>
		</Notification>}
	</SectionStack></Box> : <></>;
}