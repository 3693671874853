import { Check, CloudUpload, Delete, Info, Photo } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Box, Button, Card, Typography, Divider, CardActions, CardActionArea, IconButton, Select, MenuItem, FormControl, InputLabel, FormLabel, FormControlLabel, RadioGroup, Radio, Link, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import jax from "../helper/jax";
import { roles } from "../features/access";
import { useSelector } from "react-redux";

function CertificateUpload(props) {
	const {onUpload, field, title} = props;
	const hiddenFileInput= useRef(null);
	const [fileName, setFileName] = useState(null);

	const handleFile = function(e) {
		const fileUploaded = e.target.files[0];
		if (fileUploaded) {
			setFileName(fileUploaded.name);
			const fileReader = new FileReader();
			fileReader.readAsDataURL(e.target.files[0]);
			fileReader.addEventListener("load", function () {
				var data = this.result.match(/^data.+?base64\,(.*)$/i);
				onUpload(field, data[1], fileUploaded.type);
			});
		}
	}

	return <Card variant="outlined" sx={{height:"100%"}} flex={1}>
		<Stack pt={1} sx={{height:"100%"}}>
			<Box pb={1} textAlign="center">
				<b>{title}</b>
			</Box>
			<Divider/>
			<Stack p={1} flex={1}>
				{fileName && <Stack direction="row" alignItems="center"><Photo/><Box pl={1}>{fileName}</Box></Stack>}
				{fileName && <Box flex={1}></Box>}
				<Stack direction="row" spacing={1} mt={1} mb={1.5} justifyContent="center">
					<Button color="primary" onClick={()=>{hiddenFileInput.current.click()}} startIcon={<CloudUpload/>} size="small" variant="contained" >{fileName ? "Replace" : "Upload"}</Button>
					{fileName && <Button variant="contained" sx={{minWidth:0}} color="error" size="small" onClick={()=>{setFileName(null); onUpload(field, null); hiddenFileInput.current.value = ""}} ><Delete/></Button>}
				</Stack>
				<input type="file" accept="image/*,application/pdf" ref={hiddenFileInput} style={{display: 'none'}} onChange={handleFile}/>
			</Stack>
		</Stack>
		
	</Card>
}

export default function AddTraining(props) {
	const {open, user, lock_role, onClose, onSubmit} = props;

	const {control , handleSubmit, reset, setValue, watch, formState: { errors }} = useForm({
		defaultValues: {
			date: '',
			location: '',
			notes: '',
			cert_no: '',
			cert_type: '',
			file1: null,
			file1type: null,
			file2: null,
			file2type: null
		}
	});

	const certs = useSelector(state=>state.data.certs);
	
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);	

	const submitForm = async function(data) {
		
		try { 
			setSubmitting(true);
			var result = await jax.post('/app/verifyme', data);
			
			onSubmit(data, result.training);
			setError(false);
			
			reset();
			onClose();
		} catch (err) {
			setError("An error occurred while submitting your request.  Please try again later.");
		} finally {
			setSubmitting(false);
		}
	}

	useEffect(()=>{
		if (user) {
			reset();
		}
	}, [open]);

	return <Dialog open={open} keepMounted={false} onClose={onClose}  scroll="paper">
		
			<DialogTitle>Add Prior Training</DialogTitle>
			<DialogContent dividers>
				<form onSubmit={handleSubmit(submitForm)} id="verify-form">
					<Stack spacing={2} alignItems="stretch" className="sub-title">
						<Box pb={1} style={{marginTop:0}}>In order to add prior training to your profile, please submit the date, location and&nbsp;
							<Tooltip placement="bottom-end" slotProps={{tooltip: {sx: {fontSize:"0.8rem"}}}} arrow title="Verification of legacy training could be a certificate issued by the training program, or screen shot of an official training record (FLTMPS, MCTIMS, Relias, etc).  If you do not have documentation, a signed statement from the training program leadership with valid contact information will suffice. " >
								<Link color="info.main" fontWeight="bold"> sufficient evidence of training</Link>
							</Tooltip> to be reviewed and verified by an administrator.  You may be asked to provide additional information at your command managers discretion.</Box>
						
						<Box>
						
						<FormControl InputLabelProps={{ shrink: true }} fullWidth>
							
							<InputLabel id="cert-type-label">Training Type</InputLabel>
							<Controller control={control}  name="cert_type"  rules={{required: true}} render={({field})=><Select size="small" {...field} label="Training Type" labelId="cert-type-label" >
								{certs.map(cert=><MenuItem key={cert.id} value={cert.id}>{cert.name}</MenuItem>)}

								</Select>}>
							</Controller>
						</FormControl>
							
						</Box>
						
						<Stack direction={{xs:"column", lg: "row"}} flex={1} spacing={1}>
							<Box flex={1}><Controller control={control} name="date"  rules={{required: true}} render={({field})=><TextField size="small"  InputLabelProps={{ shrink: true }} type="date" label="Completion Date" variant="outlined"  {...field} error={!!errors.date} helperText={errors.date ? "Required" : ""}/>}></Controller></Box>
							<Box flex={2}><Controller control={control} name="location"  rules={{required: true}} render={({field})=><TextField size="small"  label="Training Program / Location" variant="outlined" fullWidth {...field} error={!!errors.location} helperText={errors.location ? "Required" : ""}/>}></Controller></Box>
						</Stack>
						
						<Box>If you were issued a paper certificate after your training, please upload a copy or photo below.</Box>
						<Stack direction="row" alignItems="stretch" spacing={1} flex={1}>
							<Box flex={1} position="relative"><CertificateUpload field="file1" title="Certificate Image" onUpload={(field, data, type)=>{setValue(field, data); setValue(field+"type", type)}}/></Box>
						</Stack>
						<Box>Please provide any additional details that may be useful in verifying your course completion (instructor name, contact information, etc) </Box>
						<Controller control={control} name="notes" render={({field})=><TextField multiline rows={2} size="small" label="Notes" variant="outlined" fullWidth {...field}/>}></Controller>
						{<Box>
							<Box>If you were issued a certificate number and wish to retain it, please enter it below...</Box>
							<Stack direction="row" spacing={1} alignItems="center" mt={2}>
								<Box  sx={{width:"16ch"}}><Controller control={control} name="cert_no" render={({field})=><TextField size="small" label="Cert #" variant="outlined" {...field}/>}></Controller></Box>
								<Box>
									<Tooltip arrow enterTouchDelay={100} placement="top" title={<Box>
										<Stack>
											<Box fontSize="1rem" mb={1}>Example of a certificate number from a printed certificate</Box>
											<img src="/images/cert_example.png" style={{width: "100%"}}/>
										</Stack>
									</Box>}>
										<Info/>
									</Tooltip>
								</Box>
							</Stack>
						</Box>}
						
					</Stack>
					
					</form>		
			</DialogContent>
			<DialogActions>
				<Stack px={1} direction="row" spacing={2} justifyContent="space-between" flex={1}>
					<Button size="small" onClick={onClose}>Cancel</Button>
					<Button size="small" variant="contained" disabled={submitting} color="primary" type="submit" form="verify-form">Submit</Button>	
				</Stack>
			</DialogActions>
			
	</Dialog>;

}