
const resources = {
	PROGRAM: 0,
	COURSE: 1,
	PERSONNEL: 2
};

const roles={
	STUDENT: 1,
	GRADUATE: 2,
	IUT: 3,
	INSTRUCTOR: 4,
	PROGRAM_MGR: 5,
	PROGRAM_DIR: 6,
	MED_DIR: 7,
	ADMIN: 8,
	ROLE_PLAYER: -1000, //This is a non-functional role used in safety reports
	PROGRAM_MANAGER_DIRECTOR: -1001 //This is a non-functional role used in safety reports
}

const roleNames = {
	"-1000": 'Role Player',
	"-1001": 'Program Manager / Director',
	1: 'Student',
	2: 'Graduate',
	3: 'Instructor Under Training',
	4: 'Instructor',
	5: 'Program Manager',
	6: 'Program Director',
	7: 'Medical Director',
	8: 'Administrator'
}

const unitRoles ={
	// ADMIN: 1,
	PERSONNEL_MGR: 2,
	// UNIT_MGR: 4,
	PROGRAM_ADMIN: 8,
	DONOR_MGR: 16,
	COMMAND_MGR: 32,
	BLOOD_SPECIALIST: 64,
	BLOOD_DRIVE_COORD: 128,
	MEDICAL_OFFICER: 256
};

const unitRoleNames={
	// 1: 'Administrator',
	2: 'Personnel Manager',
	// 4: 'Unit Manager',
	8: 'Training Program Administrator',
	16: 'Donor Manager',
	32: "Command Manager",
	64: "Blood Banking Specialist (BBS)",
	128: "Blood Drive Coordinator",
	256: "Medical Officer"
}

const actions = {
	// TRAINING ACTIONS
	CREATE_PROGRAM_COURSE: 'CREATE_PROGRAM_COURSE',
	CLOSE_COURSE: 'CLOSE_COURSE',
	VIEW_PROGRAM_COURSES: 'VIEW_PROGRAM_COURSES',
	VIEW_PROGRAM_REPORTS: 'VIEW_PROGRAM_REPORTS',
	MODIFY_PROGRAM_COURSE: 'MODIFY_PROGRAM_COURSE',
	VIEW_COURSE_REPORT: 'VIEW_COURSE_REPORT',
	MODIFY_UNIT_PROGRAM: 'MODIFY_UNIT_PROGRAM',
	VIEW_UNIT_SAFETY_REPORTS: 'VIEW_UNIT_SAFETY_REPORTS',
	CREATE_UNIT_PROGRAM: 'CREATE_UNIT_PROGRAM',
	APPOINT_PROGRAM_LEADERSHIP: 'APPOINT_PROGRAM_LEADERSHIP',
	
	// PERSONNEL ACTIONS
	VIEW_UNIT_PERSONNEL: 'VIEW_UNIT_PERSONNEL',
	MANAGE_PERSONNEL_TAGS: 'MANAGE_PERSONNEL_TAGS',
	SEND_BULK_EMAIL: 'SEND_BULK_EMAIL',
	VERIFY_LEGACY_TRAINING: 'VERIFY_LEGACY_TRAINING',


	// DONOR ACTIONS
	VIEW_UNIT_DONORS: 'VIEW_UNIT_DONORS',
	REGISTER_UNIT_DONOR: 'REGISTER_UNIT_DONOR',
	MODIFY_UNIT_DONOR: 'MODIFY_UNIT_DONOR',
	RECORD_UNIT_DONOR_SCREENING: 'RECORD_UNIT_DONOR_SCREENING',
	CREATE_UNIT_DONOR_ROSTER: 'CREATE_UNIT_DONOR_ROSTER',
	SHARE_UNIT_DONOR_ROSTER: 'SHARE_UNIT_DONOR_ROSTER',
	RELEASE_UNIT_DONOR_ROSTER: 'RELEASE_UNIT_DONOR_ROSTER',
	VIEW_UNIT_UNVERIFIED_DONORS: 'VIEW_UNIT_UNVERIFIED_DONORS',
	VERIFY_UNIT_DONORS: 'VERIFY_UNIT_DONORS',
	PRINT_UNIT_DONOR_DOCUMENTS: 'PRINT_UNIT_DONOR_DOCUMENTS',

	// UNIT_ACTIONS
	CREATE_UNIT: 'CREATE_UNIT',
	MODIFY_UNIT_DETAILS: 'MODIFY_UNIT_DETAILS',
	SET_UNIT_TARGETS: 'SET_UNIT_TARGETS',
	APPOINT_UNIT_ROLES: 'APPOINT_UNIT_ROLES',
	INITIATE_UNIT_TRANSFER: 'INITIATE_UNIT_TRANSFER',
	ACCEPT_UNIT_TRANSFER: 'ACCEPT_UNIT_TRANSFER',
	REJECT_UNIT_TRANSFER: 'REJECT_UNIT_TRANSFER',

}

const features = {
	SAFETY_REPORTING: 1,
	REFRESHER_TRAINING: 2,
	NOTIFICATIONS: 4,
	LEGACY_VERIFICATION: 8,
	COMMAND_MANAGEMENT: 16
}

const hasRole = (user, role) => {
	if (!user) {
		return false;
	}
	if (user.roles?.includes(roles.ADMIN))
		return true;
	if (Array.isArray(role)) {
		return role.some(r=>user.roles?.includes(parseInt(r)));}
	 else {
		return user.roles?.includes(parseInt(role));
	}
}

const hasAnyRole = (user, roles) => {
	if (hasRole(user, 8)) {
		return true;
	}
	if (!roles?.length) {
		return true;
	}
	return roles.some(r=>user?.roles?.includes(parseInt(r)));
}

const hasUnitRole = (user, unit, role) => {
	if (typeof(unit) == 'number') {
		role = unit;
		unit = null;
	}

	if (hasRole(user, 8)) {
		return true;
	}

	//Querying a specific unit
	if (unit) {
		if (!Array.isArray(unit.parent_ids)) {
			throw "Supplied unit must have parent_ids property";
		}
		return !!(unit.parent_ids?.some(id=>(user.units[id] & role) == role) || (user.units[unit.id] & role) == role);
	}

	return (user.unit_roles & role) == role;
}	

const hasAnyUnitRole = (user, roles, unit_id) => {

	if (hasRole(user, 8)) {
		return true;
	}

	if (!roles?.length) {
		return true;
	}

	if ((user.units[unit_id] & unitRoles.COMMAND_MGR) == unitRoles.COMMAND_MGR) {
		return true;
	}
	
	if (unit_id) {
		return roles.some(r=>(user.units[unit_id] & r) == r);
	}

	return roles.some(r=>(user?.unit_roles & r) == r);
}

const hasProgramRole = (user, role, program_id) => {
	if (hasRole(user, 8)) {
		return true;
	}
	return user.programs.find(p=>p.id == program_id && p.roles?.includes(role));
}

const hasAnyProgramRole = (user, roles, program_id) => {
	if (hasRole(user, 8)) {
		return true;
	}
	
	return roles.some(r=>user.programs.find(p=>p.id == program_id && p.inferred_roles?.includes(r)));
}

const canDoAction = function(user,action,id) {
	
	switch (action) {
		case actions.CREATE_UNIT_PROGRAM:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id);
		case actions.CREATE_PROGRAM_COURSE:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id) ||  hasAnyProgramRole(user, [roles.PROGRAM_DIR,roles.PROGRAM_MGR], id);
		case actions.CLOSE_COURSE:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id) ||  hasAnyProgramRole(user, [roles.PROGRAM_DIR,roles.PROGRAM_MGR], id);
		case actions.VIEW_PROGRAM_COURSES:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id) ||  hasAnyProgramRole(user, [roles.PROGRAM_DIR,roles.PROGRAM_MGR], id);
		case actions.VIEW_PROGRAM_REPORTS:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id) ||  hasAnyProgramRole(user, [roles.PROGRAM_DIR,roles.PROGRAM_MGR], id);
		case actions.MODIFY_PROGRAM_COURSE:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id) ||  hasAnyProgramRole(user, [roles.PROGRAM_DIR,roles.PROGRAM_MGR], id);
		case actions.VIEW_COURSE_REPORT:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id) ||  hasAnyProgramRole(user, [roles.PROGRAM_DIR,roles.PROGRAM_MGR], id);
		case actions.VIEW_UNIT_PERSONNEL:
			return hasAnyUnitRole(user, [unitRoles.PERSONNEL_MGR], id);
		case actions.MANAGE_PERSONNEL_TAGS:
			return hasAnyUnitRole(user, [unitRoles.PERSONNEL_MGR], id);
		case actions.MANAGE_PERSONNEL_TAGS:
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.MODIFY_UNIT_PROGRAM:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN], id);
		case actions.VIEW_UNIT_SAFETY_REPORTS:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN, unitRoles.MEDICAL_OFFICER], id);
		case actions.APPOINT_PROGRAM_LEADERSHIP:
			return hasAnyUnitRole(user, [unitRoles.PROGRAM_ADMIN ], id);
		case actions.VIEW_UNIT_DONORS:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.MEDICAL_OFFICER], id);
		case actions.REGISTER_UNIT_DONOR:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.MODIFY_UNIT_DONOR:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.RECORD_UNIT_DONOR_SCREENING:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.CREATE_UNIT_DONOR_ROSTER:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.SHARE_UNIT_DONOR_ROSTER:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.RELEASE_UNIT_DONOR_ROSTER:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.VIEW_UNIT_UNVERIFIED_DONORS:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.MEDICAL_OFFICER], id);
		case actions.VERIFY_UNIT_DONORS:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.MEDICAL_OFFICER], id);
		case actions.PRINT_UNIT_DONOR_DOCUMENTS:
			return hasAnyUnitRole(user, [unitRoles.DONOR_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST], id);
		case actions.CREATE_UNIT:
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.MODIFY_UNIT_DETAILS:
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.SET_UNIT_TARGETS: 
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.APPOINT_UNIT_ROLES: 
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.INITIATE_UNIT_TRANSFER: 
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.ACCEPT_UNIT_TRANSFER: 
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.REJECT_UNIT_TRANSFER: 
			return hasAnyUnitRole(user, [unitRoles.COMMAND_MGR], id);
		case actions.SEND_BULK_EMAIL:
			return hasRole(user, roles.ADMIN);
	}
	return false;
}

const can = function(user, action, id) {
	if (hasRole(user, 8)) {
		return true;
	}

	if (Array.isArray(action)) {
		return action.some(a=>canDoAction(user,a,id));
	} else {
		return canDoAction(user,action,id);
	}
	
	return false;
	// return user.courses.some(c=>c.id == id &&  [5,6,7].includes(c.role_id));
	
}

module.exports = {resources, actions, can, hasRole, hasAnyRole, hasUnitRole, hasAnyUnitRole, hasProgramRole, hasAnyProgramRole, roles, unitRoles, roleNames, unitRoleNames, features};
